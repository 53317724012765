"use client";

import { CarouselAutoplay } from "@/components/carousel/carouselAutoplay";
import { Footer } from "@/components/footer";
import Timeline from "@/components/timeline/timeline";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/Button";
import { Heading } from "@/components/ui/Heading";
import { HREFLink } from "@/components/ui/HREFLink";
import { PageContentContainer } from "@/components/ui/PageContentContainer";
import { cn } from "@/lib/utils";
import { set } from "lodash";
import Image from "next/image";
import React, { useEffect, useRef, useState } from "react";

const quotesData = [
  {
    quote: "The first step is to establish that something is possible; then probability will occur.",
    author: "Elon Musk",
  },
  {
    quote: "The only thing that is constant is change.",
    author: "Heraclitus",
  },
  {
    quote: "If you are going to do anything innovative you have to be willing to be misunderstood.",
    author: "Jeff Bezos",
  },
  {
    quote: "The truth will set you free, but first it will piss you off.",
    author: "Joe Klaas",
  },
  {
    quote: "You are the universe expressing itself as a human for a little while.",
    author: "Eckhart Tolle",
  },
  {
    quote: "It's not about optimism vs pessimism; those are both excuses for laziness. It's about human agency.",
    author: "Peter Thiel",
  },
  {
    quote: "The best way to predict the future is to invent it.",
    author: "Alan Kay",
  },
  {
    quote: "The obstacle is the way.",
    author: "Marcus Aurelius",
  },
  {
    quote: "One of the really tough things is figuring out what questions to ask. Once you figure out the question, then the answer is relatively easy.",
    author: "Elon Musk",
  },
];

export default function RootPage() {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [timelineHeight, setTimelineHeight] = useState<number | undefined>(undefined);
  const [timelineBottom, setTimelineBottom] = useState<number | undefined>(undefined);
  const containerRef = useRef<HTMLDivElement>(null);

  const onHandleLoadedAll = (bool: boolean = false) => {
    setIsLoaded(bool);
  };

  const handleTimelineLoaded = (height: number) => {
    console.log("handleTimelineLoaded", height);
    if (height === 0) {
      setTimelineHeight(undefined);
      return;
    }
    setTimelineHeight(height);
  };

  const quoteNodes = quotesData.map((item, idx) => (
    <div key={"quote_" + idx} className="flex h-fit flex-col justify-between px-4 pb-2 pt-4">
      <p className="text-center text-lg leading-snug">{'"' + item.quote + '"'}</p>
      <p className="text-right text-sm">- {item.author}</p>
    </div>
  ));

  useEffect(() => {
    if (containerRef.current) {
      const handleResize = () => {
        if (containerRef.current) {
          const height = containerRef.current.getBoundingClientRect().height;
          console.log("handleResize", height);
          //Bottom of containerRef
          const bottom = containerRef.current.getBoundingClientRect().bottom;
          setTimelineBottom(bottom);
          //setTimelineHeight(height);
        }
      };
      handleResize();
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [timelineHeight]);

  return (
    <div className="flex min-h-screen flex-col justify-between bg-background">
      <PageContentContainer
        ref={containerRef}
        variant="padded"
        className={cn("flex flex-col justify-between pt-4 transition-all", { "justify-normal": isLoaded })}
      >
        <div className={cn("relative pb-6", { "": timelineHeight })}>
          <div className="text-primary-dark">
            <div className="float-left m-4">
              <Avatar className="h-14 w-14">
                <AvatarImage src={""} alt={"Erik Koning"} />
                <AvatarFallback className="bg-skyBlue text-xl">EK</AvatarFallback>
              </Avatar>
            </div>
            <p className="text-base">
              Hi, I&apos;m Erik. Full stack software developer, truth seeker, and adventurer. I have a wide breadth of skills, but I&apos;m deep with
              React, TypeScript and its ecosystem, backend cloud services, deployment to serverless, algorithms, firmware, embedded devices, systems
              thinking, and wind sports. I seek hard problems and aim to solve them with code to organize and interpret solutions for those who share the
              problem.
            </p>
          </div>
          <Heading className="text-1xl mt-4 font-semibold">Achievements:</Heading> {/* possible we dont need this div? */}
          <Timeline className="" onHandleLoadedAll={onHandleLoadedAll} handleTimelineLoaded={handleTimelineLoaded} />
        </div>
        <div className="max-w-screen flex flex-col justify-between">
          <Heading className="text-1xl mt-4 font-semibold">Agreeable Quotes:</Heading>
          <div className="px-12">
            <CarouselAutoplay items={quoteNodes} contentName="Quote" className="z-40 text-lg duration-300" />
          </div>
          {/*footer*/}
        </div>
      </PageContentContainer>
      <Footer />
    </div>
  );
}

RootPage.displayName = "RootPage";
